/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-table-dropdown {
  width: auto;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-table-list-toolbar {
  overflow-x: auto;
  overflow-y: hidden;
  line-height: 1;
}
.ant-pro-table-list-toolbar-container {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
}
.ant-pro-table-list-toolbar-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
}
.ant-pro-table-list-toolbar-search:not(:last-child) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ant-pro-table-list-toolbar-setting-item {
  margin: 0 4px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 16px;
  cursor: pointer;
}
.ant-pro-table-list-toolbar-setting-item span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-pro-table-list-toolbar-setting-item:hover {
  color: #1890ff;
}
.ant-pro-table-list-toolbar-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ant-pro-table-list-toolbar-right {
  display: flex;
  justify-content: flex-end;
}
.ant-pro-table-list-toolbar-extra-line {
  margin-bottom: 16px;
}
.ant-pro-table-list-toolbar-filter {
  display: flex;
  align-items: center;
}
.ant-pro-table-list-toolbar-filter:not(:last-child) {
  margin-right: 16px;
}
.ant-pro-table-list-toolbar-filter .ant-pro-table-search {
  margin: 0;
  padding: 0;
}
.ant-pro-table-list-toolbar-inline-menu-item {
  display: inline-block;
  margin-right: 24px;
  cursor: pointer;
  opacity: 0.75;
}
.ant-pro-table-list-toolbar-inline-menu-item-active {
  font-weight: bold;
  opacity: 1;
}
.ant-pro-table-list-toolbar-dropdownmenu-label {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
.ant-pro-table-list-toolbar .ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0;
}
.ant-pro-table-list-toolbar .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0;
}
.ant-pro-table-list-toolbar .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-list {
  margin-top: 0;
}
.ant-pro-table-list-toolbar .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
  padding-top: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-core-label-tip {
  display: flex;
  align-items: center;
}
.ant-pro-core-label-tip-icon {
  display: block;
  margin-left: 4px;
  cursor: pointer;
}
.ant-pro-core-label-tip-subtitle {
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
  white-space: nowrap;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-field-dropdown {
  width: auto;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-table-search {
  margin-bottom: 16px;
  padding: 24px;
  padding-bottom: 0;
  background: #fff;
}
.ant-pro-table-search::before {
  display: table;
  content: '';
}
.ant-pro-table-search::after {
  display: table;
  clear: both;
  content: '';
}
.ant-pro-table-search .ant-form-horizontal .ant-form-item-control {
  overflow: hidden;
}
.ant-pro-table-search.ant-pro-table-form {
  margin: 0;
  padding: 0 16px;
  overflow: unset;
}
.ant-pro-table-search-light {
  margin-bottom: 0;
  padding: 16px 0;
}
.ant-pro-table-search-form-option .ant-form-item {
  margin: 0;
}
.ant-pro-table-search-form-option .ant-form-item-label {
  opacity: 0;
}
.ant-pro-table-search-form-option .ant-form-item-control-input {
  justify-content: flex-start;
}
@media (max-width: 575px) {
  .ant-pro-table-search {
    height: auto !important;
    padding-bottom: 24px;
  }
  .ant-pro-table-search .ant-form-item-label {
    min-width: 80px;
    text-align: left;
  }
}

.ant-pro-form-light-filter {
  line-height: 30px;
}
.ant-pro-form-light-filter::before {
  display: block;
  height: 0;
  visibility: hidden;
  content: '.';
}
.ant-pro-form-light-filter-small {
  line-height: 1.5715;
}
.ant-pro-form-light-filter-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;
  margin-right: -4px;
}
.ant-pro-form-light-filter-item {
  margin-top: 8px;
}
.ant-pro-form-light-filter-item:not(:last-child) {
  margin-right: 8px;
}
.ant-pro-form-light-filter-formlabel {
  margin-bottom: 2px;
}
.ant-pro-form-light-filter-line {
  min-width: 198px;
}
.ant-pro-form-light-filter-line .ant-form-item {
  flex-direction: column;
  margin-bottom: 0;
}
.ant-pro-form-light-filter-line:not(:first-child) {
  margin-top: 16px;
  margin-bottom: 8px;
}
.ant-pro-form-light-filter .ant-form-item {
  margin-bottom: 0;
}
.ant-pro-form-light-filter-collapse-icon {
  width: 32px;
  height: 32px;
  line-height: 35px;
  border-radius: 50%;
}
.ant-pro-form-light-filter-effective .ant-pro-form-light-filter-collapse-icon {
  background-color: rgba(0, 0, 0, 0.04);
}

.ant-pro-core-field-label {
  display: inline-block;
  height: 30px;
  padding: 0 4px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 2px;
  cursor: pointer;
}
.ant-pro-core-field-label-active {
  padding: 0 12px;
  background-color: rgba(0, 0, 0, 0.04);
}
.ant-pro-core-field-label-active.ant-pro-core-field-label-allow-clear:hover:not(.ant-pro-core-field-label-disabled) .ant-pro-core-field-label-arrow {
  display: none;
}
.ant-pro-core-field-label-active.ant-pro-core-field-label-allow-clear:hover:not(.ant-pro-core-field-label-disabled) .ant-pro-core-field-label-close {
  display: inline-block;
}
.ant-pro-core-field-label-icon {
  margin-top: -2px;
  margin-left: 4px;
  padding: 1px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  vertical-align: middle;
}
.ant-pro-core-field-label-icon.ant-pro-core-field-label-close {
  display: none;
  margin-top: -4px;
  padding: 3px;
  color: #fff;
  font-size: 8px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
.ant-pro-core-field-label-icon.ant-pro-core-field-label-close:hover {
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-pro-core-field-label-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-pro-core-field-label-disabled .ant-pro-core-field-label-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-pro-core-field-label-small {
  height: 24px;
  padding: 0 4px;
  font-size: 12px;
  line-height: 24px;
}
.ant-pro-core-field-label-small.ant-pro-core-field-label-active {
  padding: 0 8px;
}
.ant-pro-core-field-label-small .ant-pro-core-field-label-icon {
  padding: 0;
}
.ant-pro-core-field-label-small .ant-pro-core-field-label-close {
  margin-top: -2px;
  padding: 3px;
  font-size: 6px;
}
.ant-pro-core-field-label-bordered {
  height: 32px;
  padding: 0 12px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-pro-core-field-label-bordered.ant-pro-core-field-label-small {
  height: 24px;
  padding: 0 8px;
}
.ant-pro-core-field-label-bordered.ant-pro-core-field-label-active {
  background-color: #fff;
}

.ant-pro-core-field-dropdown-label {
  cursor: pointer;
}
.ant-pro-core-field-dropdown-overlay {
  min-width: 200px;
  margin-top: 4px;
  background-color: #fff;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-pro-core-field-dropdown-content {
  padding: 16px;
}

.ant-pro-core-dropdown-footer {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 8px;
  border-top: 1px solid #f0f0f0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
[class^=ant-],
[class*= ant-],
[class^=ant-] *,
[class*= ant-] *,
[class^=ant-] *::before,
[class*= ant-] *::before,
[class^=ant-] *::after,
[class*= ant-] *::after {
  box-sizing: border-box;
}
/* stylelint-disable at-rule-no-unknown */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum';
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #40a9ff;
}
a:active {
  color: #096dd9;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #1890ff;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.fade-enter,
.fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.fade-leave.fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.fade-enter,
.fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.move-up-enter,
.move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-up-enter.move-up-enter-active,
.move-up-appear.move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.move-up-leave.move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-up-enter,
.move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-down-enter,
.move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-down-enter.move-down-enter-active,
.move-down-appear.move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.move-down-leave.move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-down-enter,
.move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-left-enter,
.move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-left-enter.move-left-enter-active,
.move-left-appear.move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.move-left-leave.move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-left-enter,
.move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-right-enter,
.move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-right-enter.move-right-enter-active,
.move-right-appear.move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.move-right-leave.move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-right-enter,
.move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #1890ff;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #1890ff;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.slide-up-enter,
.slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-up-enter.slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.slide-up-leave.slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-up-enter,
.slide-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-enter,
.slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-left-enter,
.slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-left-enter.slide-left-enter-active,
.slide-left-appear.slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.slide-left-leave.slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-left-enter,
.slide-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-right-enter,
.slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-right-enter.slide-right-enter-active,
.slide-right-appear.slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.slide-right-leave.slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-right-enter,
.slide-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.zoom-enter,
.zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.zoom-leave.zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-enter,
.zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-enter-prepare,
.zoom-appear-prepare {
  transform: none;
}
.zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-enter,
.zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-enter.zoom-big-enter-active,
.zoom-big-appear.zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.zoom-big-leave.zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-big-enter,
.zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-enter-prepare,
.zoom-big-appear-prepare {
  transform: none;
}
.zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-fast-enter.zoom-big-fast-enter-active,
.zoom-big-fast-appear.zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.zoom-big-fast-leave.zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-fast-enter-prepare,
.zoom-big-fast-appear-prepare {
  transform: none;
}
.zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-up-enter,
.zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-up-enter.zoom-up-enter-active,
.zoom-up-appear.zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.zoom-up-leave.zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-up-enter,
.zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-up-enter-prepare,
.zoom-up-appear-prepare {
  transform: none;
}
.zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-down-enter,
.zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-down-enter.zoom-down-enter-active,
.zoom-down-appear.zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.zoom-down-leave.zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-down-enter,
.zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-down-enter-prepare,
.zoom-down-appear-prepare {
  transform: none;
}
.zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-left-enter,
.zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-left-enter.zoom-left-enter-active,
.zoom-left-appear.zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.zoom-left-leave.zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-left-enter,
.zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-left-enter-prepare,
.zoom-left-appear-prepare {
  transform: none;
}
.zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-right-enter,
.zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-right-enter.zoom-right-enter-active,
.zoom-right-appear.zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.zoom-right-leave.zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-right-enter,
.zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-right-enter-prepare,
.zoom-right-appear-prepare {
  transform: none;
}
.zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-xs {
  width: 104px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-s {
  width: 216px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-sm {
  width: 216px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-m {
  width: 328px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-md {
  width: 328px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-l {
  width: 440px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-lg {
  width: 440px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-xl {
  width: 552px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-form-group-title {
  margin-bottom: 16px;
  font-weight: bold;
}
.ant-pro-form-group-container {
  flex-wrap: wrap;
}
.ant-pro-form-group-twoLine {
  display: block;
  width: 100%;
}
.ant-pro-form-group-twoLine .ant-pro-form-group-title {
  width: 100%;
  margin: 8px 0;
}
.ant-pro-form-group-twoLine .ant-pro-form-group-container {
  padding-left: 16px;
}
.ant-pro-form-group-twoLine .ant-space-item,
.ant-pro-form-group-twoLine .ant-form-item {
  width: 100%;
}
.ant-pro-form-group-twoLine .ant-form-item-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ant-pro-form-group-twoLine .ant-form-item-control-input {
  align-items: center;
  justify-content: flex-end;
}
.ant-pro-form-group-twoLine .ant-form-item-control-input-content {
  flex: none;
}

.ant-pro-field-date-picker-light .ant-calendar-picker,
.ant-pro-field-date-picker-light .ant-picker {
  position: absolute;
  width: 80px;
  height: 28px;
  overflow: hidden;
  visibility: hidden;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-field-index-column {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
}
.ant-pro-field-index-column-border {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  background-color: #314659;
  border-radius: 9px;
}
.ant-pro-field-index-column-border.top-three {
  background-color: #979797;
}

.ant-pro-field-select-light-select .ant-select {
  position: absolute;
  width: 153px;
  height: 28px;
  visibility: hidden;
}
.ant-pro-field-select-light-select .ant-select-selector {
  height: 28px;
}
.ant-pro-field-select-light-select.ant-pro-field-select-light-select-searchable .ant-select {
  width: 200px;
}
.ant-pro-field-select-light-select.ant-pro-field-select-light-select-searchable .ant-select-selector {
  height: 28px;
}

.ant-pro-field-checkbox-vertical .ant-checkbox-group-item {
  display: block;
  margin-right: 0;
}

.ant-pro-field-radio-vertical .ant-radio-wrapper {
  display: block;
  margin-right: 0;
}

.ant-pro-field-light-wrapper-collapse-label {
  padding: 1;
}
.ant-pro-field-light-wrapper-container .ant-form-item {
  margin-bottom: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-table {
  z-index: 1;
}
.ant-pro-table:not(:root):fullscreen {
  min-height: 100vh;
  overflow: auto;
  background: #fff;
}
.ant-pro-table-extra {
  margin-bottom: 16px;
}
.ant-pro-table-polling .ant-pro-table-list-toolbar-setting-item .anticon.anticon-reload {
  transform: rotate(0deg);
  transition: 0.3s transform;
  animation: turn 1s linear infinite;
}
.ant-pro-table td.ant-table-cell > a {
  font-size: 14px;
}
.ant-pro-table .ant-table .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: 0;
}
.ant-pro-table .ant-table.ant-table-middle .ant-pro-table {
  margin: -12px -8px;
}
@keyframes turn {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-table {
  z-index: 1;
}
.ant-pro-table:not(:root):fullscreen {
  min-height: 100vh;
  overflow: auto;
  background: #fff;
}
.ant-pro-table-extra {
  margin-bottom: 16px;
}
.ant-pro-table-polling .ant-pro-table-list-toolbar-setting-item .anticon.anticon-reload {
  transform: rotate(0deg);
  transition: 0.3s transform;
  animation: turn 1s linear infinite;
}
.ant-pro-table td.ant-table-cell > a {
  font-size: 14px;
}
.ant-pro-table .ant-table .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: 0;
}
.ant-pro-table .ant-table.ant-table-middle .ant-pro-table {
  margin: -12px -8px;
}
@keyframes turn {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ant-pro-table-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 24px;
}
.ant-pro-table-toolbar-option {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ant-pro-table-toolbar-title {
  flex: 1;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.85;
}
@media (max-width: 480px) {
  .ant-pro-table .ant-table {
    width: 100%;
    overflow-x: auto;
  }
  .ant-pro-table .ant-table-thead > tr > th,
  .ant-pro-table .ant-table-tbody > tr > th,
  .ant-pro-table .ant-table-thead > tr > td,
  .ant-pro-table .ant-table-tbody > tr > td {
    white-space: pre;
  }
  .ant-pro-table .ant-table-thead > tr > th > span,
  .ant-pro-table .ant-table-tbody > tr > th > span,
  .ant-pro-table .ant-table-thead > tr > td > span,
  .ant-pro-table .ant-table-tbody > tr > td > span {
    display: block;
  }
}
@media (max-width: 575px) {
  .ant-pro-table-toolbar {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    margin-bottom: 16px;
    margin-left: 16px;
    padding: 8px;
    padding-top: 16px;
    line-height: normal;
  }
  .ant-pro-table-toolbar-title {
    margin-bottom: 16px;
  }
  .ant-pro-table-toolbar-option {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .ant-pro-table-toolbar-default-option {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-table-column-setting {
  width: auto;
}
.ant-pro-table-column-setting-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
}
.ant-pro-table-column-setting-overlay .ant-popover-inner-content {
  padding: 0px;
  padding-bottom: 8px;
}
.ant-pro-table-column-setting-overlay .ant-tree-treenode {
  align-items: center;
}
.ant-pro-table-column-setting-overlay .ant-tree-treenode:hover {
  background-color: #e6f7ff;
}
.ant-pro-table-column-setting-overlay .ant-tree-treenode:hover .ant-pro-table-column-setting-list-item-option {
  display: block;
}
.ant-pro-table-column-setting-overlay .ant-tree-treenode .ant-tree-checkbox {
  top: 0;
  margin: 0;
  margin-right: 4px;
}
.ant-pro-table-column-setting-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 8px;
}
.ant-pro-table-column-setting-list.ant-pro-table-column-setting-list-group {
  padding-top: 0;
}
.ant-pro-table-column-setting-list-title {
  margin-top: 6px;
  margin-bottom: 6px;
  padding-left: 24px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
.ant-pro-table-column-setting-list-item {
  display: flex;
  align-items: center;
}
.ant-pro-table-column-setting-list-item-title {
  flex: 1;
}
.ant-pro-table-column-setting-list-item-option {
  display: none;
  float: right;
  cursor: pointer;
}
.ant-pro-table-column-setting-list-item-option > span > span.anticon {
  color: #1890ff;
}
.ant-pro-table-column-setting-list-item-option > span + span {
  margin-left: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-table-alert {
  margin-bottom: 16px;
}
.ant-pro-table-alert .ant-alert.ant-alert-no-icon {
  padding: 12px 24px;
}
.ant-pro-table-alert-info {
  display: flex;
  align-items: center;
  transition: 0.3 all;
}
.ant-pro-table-alert-info-content {
  flex: 1;
}
.ant-pro-table-alert-info-option {
  min-width: 48px;
  padding-left: 16px;
}

